import React, { useEffect} from 'react';
import { Text, Container } from '@chakra-ui/react';

const PrivacyPolicyScreen = () => {

    useEffect(() => {
            document.title = 'Privacy & Policy | REGENT NX CHAIRS';
          }, []);

  return (
   
    <Container maxW="container.lg" >
      <Text fontSize="2xl" fontWeight="bold" mx="auto" mt={{ base: '80px', sm: '140px', lg: '140px' }} textAlign="center">
        Privacy Policy
      </Text>

      <Text mb={4}>
        Welcome to Regent NX Chairs. Your privacy is important to us, and we are committed to protecting your personal information. This policy explains how we collect, use, and safeguard your data.
      </Text>

      <Text fontSize="md" fontWeight="bold" mt={6} mb={2}>1. Information We Collect</Text>
      <Text>
        - **Personal Information**: Name, email, phone number, and other details provided voluntarily.<br />  
        - **Usage Data**: Browsing behavior, IP address, device information, and interaction patterns.<br /> 
        - **Cookies and Tracking Technologies**: We use cookies to enhance your experience.<br />
      </Text>

      <Text fontSize="md" fontWeight="bold" mt={6} mb={2}>2. How We Use Your Information</Text>
      <Text>
        - To provide customer support and respond to inquiries.<br />
        - To improve our website, products, and services.<br />
        - To analyze trends and enhance user experience.<br />
        - To comply with legal obligations.<br />
      </Text>

      <Text fontSize="md" fontWeight="bold" mt={6} mb={2}>3. Data Security</Text>
      <Text>
        We implement strict security measures to protect your personal data. However, no online transmission is 100% secure, and we cannot guarantee absolute protection.
      </Text>

      <Text fontSize="md" fontWeight="bold" mt={6} mb={2}>4. Cookies and Tracking</Text>
      <Text>
        - We use cookies to remember user preferences and analyze traffic.<br />  
        - You can disable cookies through your browser settings.<br />  
        - Some third-party services may also collect tracking data.<br />
      </Text>

      <Text fontSize="md" fontWeight="bold" mt={6} mb={2}>5. Third-Party Sharing</Text>
      <Text>
        - We do not sell or rent personal data.<br />  
        - Information may be shared with trusted partners for operational purposes.<br />  
        - Legal compliance may require us to disclose data.<br />
      </Text>

      <Text fontSize="md" fontWeight="bold" mt={6} mb={2}>6. Your Rights</Text>
      <Text>
        - You can request access to your personal data.<br />  
        - You can update or delete your information upon request.<br />  
        - You have the right to opt out of marketing communications.<br />
      </Text>

      <Text fontSize="md" fontWeight="bold" mt={6} mb={2}>7. Data Retention</Text>
      <Text>
        - We retain data only as long as necessary.<br />  
        - Data may be deleted upon request, subject to legal obligations.<br />
      </Text>

      <Text fontSize="md" fontWeight="bold" mt={6} mb={2}>8. Policy Updates</Text>
      <Text>
        We may update this policy periodically. Any changes will be reflected on this page.
      </Text>

      <Text fontSize="md" fontWeight="bold" mt={6} mb={2}>9. Contact Us</Text>
      <Text>
        If you have questions or concerns about our privacy policy, reach out to us:  
        📧 Email: regentnxchairs@gmail.com  
      </Text>
    </Container>
  );
};

export default PrivacyPolicyScreen;
