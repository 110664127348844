import React, { useEffect } from 'react'; 
import Banner1 from '../components/Banner1';
import Banner2 from '../components/Banner2';
import Banner3 from '../components/Banner3';
import Banner4 from '../components/Banner4';
import Banner5 from '../components/Banner5';
import Banner7 from '../components/Banner7';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Popular from '../components/Popular';

const HomeScreenBanner = () => {
  
  useEffect(() => {
    document.title = "REGENT NX CHAIRS | Premium Chairs for Home, Office & More"; 
  }, []); 

  return (
    <>
      <Banner1 />
      <Banner2 />
      <Banner3 />
      <Popular />
      <Banner5 />
      <Banner4 />
      <Banner7 />
    </>
  );
};

export default HomeScreenBanner;
