import React, { useEffect} from 'react';
import { Text, Container } from '@chakra-ui/react';

const TermsScreen = () => {

    useEffect(() => {
            document.title = 'Terms & Conditions | REGENT NX CHAIRS';
          }, []);

  return (
    <Container maxW="container.lg" >
      <Text fontSize="2xl" fontWeight="bold" textAlign="center" mt={{ base: '80px', sm: '140px', lg: '140px' }}>
        Terms and Conditions
      </Text>

        <Text mb={4}>
          Welcome to Regent NX Chairs. By accessing or using our website, you agree to comply with the following terms and conditions. Please read them carefully.
        </Text>

        <Text fontSize="md" fontWeight="bold" mt={6} mb={2}> 1. Website Use</Text>
        <Text>
          This website is provided for informational purposes only. While you may explore our products and services, online purchases are currently unavailable.
        </Text>

        <Text fontSize="md" fontWeight="bold" mt={6} mb={2}> 2. Intellectual Property</Text>
        <Text>
          All content on this site, including text, images, logos, and graphics, is owned by Regent NX Chairs and protected under copyright and trademark laws. Unauthorized use, reproduction, or distribution is prohibited.
        </Text>

        <Text fontSize="md" fontWeight="bold" mt={6} mb={2}> 3. User Responsibilities</Text>
        <Text>
          You agree to use this website only for lawful purposes. Any attempt to interfere with the website’s security, functionality, or content is strictly prohibited.
        </Text>

        <Text fontSize="md" fontWeight="bold" mt={6} mb={2}> 4. Limitation of Liability</Text>
        <Text>
          Regent NX Chairs is not responsible for any direct, indirect, incidental, or consequential damages resulting from the use of this website.
        </Text>

        <Text fontSize="md" fontWeight="bold" mt={6} mb={2}> 5. Third-Party Links</Text>
        <Text>
          Our website may contain links to third-party websites. We do not endorse or take responsibility for the content, security, or policies of these external sites.
        </Text>

        <Text fontSize="md" fontWeight="bold" mt={6} mb={2}> 6. Privacy and Data Collection</Text>
        <Text>
          Any personal information collected through this website is handled in accordance with our <a href="/privacy-policy" style={{ color: 'blue', textDecoration: 'underline' }}>Privacy Policy</a>.
        </Text>

        <Text fontSize="md" fontWeight="bold" mt={6} mb={2}> 7. Changes to Terms</Text>
        <Text>
          We reserve the right to modify these terms at any time. Continued use of the website after changes are made constitutes acceptance of the updated terms.
        </Text>

        <Text fontSize="md" fontWeight="bold" mt={6} mb={2}> 8. Contact Information</Text>
        <Text>
          For any questions regarding these terms, please contact us at: 
          📧 Email: regentnxchairs@gmail.com 
        </Text>
    </Container>
  );
};

export default TermsScreen;
