import { ChakraProvider,  ColorModeScript, Flex } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import CartScreen from './screens/CartScreen';
import HomeScreen from './screens/HomeScreen';
import AboutScreen from './screens/AboutScreen';
import LoginScreen from './screens/LoginScreen';
// import OrderScreen from './screens/OrderScreen';
import OrderListScreen from './screens/OrderListScreen';
// import PaymentScreen from './screens/PaymentScreen';
// import PlaceOrderScreen from './screens/PlaceOrderScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProductScreen from './screens/ProductScreen';
import ProductDisplayScreen from './screens/ProductDisplayScreen';
import ProductTypeScreen from './screens/ProductTypeScreen';
import LatestCollectionScreen from './screens/LatestCollectionScreen';
import ProfileScreen from './screens/ProfileScreen';
import RegisterScreen from './screens/RegisterScreen';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
// import ShippingScreen from './screens/ShippingScreen';
import UserEditScreen from './screens/UserEditScreen';
import UserListScreen from './screens/UserListScreen';
import SearchScreen from './screens/SearchScreen';
import theme from './components/Theme';
import ContactScreen from './screens/ContactScreen';
import TermsScreen from './screens/TermsScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  return (
    <ChakraProvider theme={theme}>
       <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <BrowserRouter>
        <Flex
          direction="column"
          minHeight="100vh" 
        >
          <Header />
          <Flex
            flex="1"
            direction="column"
          >
            <ScrollToTop />
            <Routes>
              <Route path='/' element={<HomeScreen />} />
              <Route path='/about' element={<AboutScreen />} />
              <Route path='/contact' element={<ContactScreen />} />
              <Route path='/product/:id' element={<ProductScreen />} />
              <Route path='/products/:category' element={<ProductDisplayScreen />} />
              <Route path='/:type' element={<ProductTypeScreen />} />
              <Route path='/latest' element={<LatestCollectionScreen />} />
              {/* <Route path='/cart' element={<CartScreen />} /> */}
              <Route path='/cart/:id' element={<CartScreen />} />
              <Route path='/product/:id' element={<ProductScreen />} />
              <Route path='/search' element={<SearchScreen />} />
              <Route path='/login' element={<LoginScreen />} />
              <Route path='/register' element={<RegisterScreen />} />
              <Route path='/profile' element={<ProfileScreen />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
       				<Route path="/reset-password/:token" element={<ResetPassword />} />
              {/* <Route path='/shipping' element={<ShippingScreen />} /> */}
              {/* <Route path='/payment' element={<PaymentScreen />} /> */}
              {/* <Route path='/placeorder' element={<PlaceOrderScreen />} /> */}
              {/* <Route path='/order/:id' element={<OrderScreen />} /> */}
              <Route path='/admin/userlist' element={<UserListScreen />} />
              <Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
              <Route path='/admin/productlist' element={<ProductListScreen />} />
              <Route path='/admin/product/:id/edit'element={<ProductEditScreen />} />
			        <Route path='/admin/orderlist' element={<OrderListScreen />} /> 
              <Route path='/terms-conditions' element={<TermsScreen />} />
              <Route path='/privacy-policy' element={<PrivacyPolicyScreen />} />
            </Routes>
          </Flex>
          <Footer />
        </Flex>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
