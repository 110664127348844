import { Flex, Text, useColorModeValue, Link, Divider } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  const footerBg = useColorModeValue('white', 'black');
  const footerColor = useColorModeValue('black', 'whiteAlpha.800');
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <Flex
      as='footer'
      direction='column'
      letterSpacing='wide'
      align='center'
      justify='center'
      py={{ base: '3', md: '3' }}
      width='100%'
      bg={footerBg}
      color={footerColor}
      position='relative'
      bottom='0'
    >
      <Text textAlign='center' fontSize={{ base: 'sm', md: 'md' }} mb={1}>
        &copy; {currentYear} Regent NX Chairs. All Rights Reserved.
      </Text>

      {/* Line Separator */}
      <Divider width="80%" my={2} />

      <Flex gap={2} fontSize="sm">
        <Link as={RouterLink} to="/privacy-policy" _hover={{ textDecoration: "underline" }}>
          Privacy Policy
        </Link>
        <Text>|</Text>
        <Link as={RouterLink} to="/terms-conditions" _hover={{ textDecoration: "underline" }}>
          Terms & Conditions
        </Link>
      </Flex>
    </Flex>
  );
};

export default Footer;
