import { Box, Flex, Heading, Image, Link, useColorMode } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const ProductCard = ({ product }) => {
  const { colorMode } = useColorMode();
  
  const cardBgColor = colorMode === 'dark' ? 'white' : 'white';
  const borderColor = colorMode === 'dark' ? 'gray.600' : 'gray.200';
  const textColor = colorMode === 'dark' ? 'blackAlpha.900' : 'blackAlpha.900';
  
  return (
    <Link
      as={RouterLink}
      to={`/product/${product._id}`}
      _hover={{ textDecoration: 'none' }}
    >
      <Box
        borderRadius="0"
        bgColor={cardBgColor}
        transition="all 0.3s ease"
        _hover={{ shadow: "lg", transform: "scale(1.02)", zIndex: "1" }}
        overflow="hidden"
        h="100%"
        position="relative"
        px="0"
        borderBottom="1px solid" 
        borderRight="1px solid" 
        borderColor={borderColor}
      >
        <Box
          position="relative"
          w="full"
          h={{ base: "250px", sm: "250px", md: "300px", lg: "420px" }}
          overflow="hidden"
        >
          <Image
            src={product.image1}
            alt={product.name}
            w="full"
            h="full"
            objectFit="contain"
            transition="opacity 0.5s ease"
            opacity="1"
            _hover={{ opacity: "0" }}
            position="absolute"
            top="0"
            left="0"
          />
          <Image
            src={product.image2}
            alt={`${product.image1} `}
            w="full"
            h="full"
            objectFit="contain"
            position="absolute"
            top="0"
            left="0"
            transition="opacity 0.5s ease"
            opacity="0"
            _hover={{ opacity: "1" }}
          />
        </Box>
        <Flex
          direction="column"
          py="0"
          px="3"
          textAlign="left"
          h="full"
        >
          <Heading
            as="h4"
            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
            fontWeight="300"
            mb="2"
            color={textColor}
            letterSpacing="0.05em"
          >
            {product.name}
          </Heading>
        </Flex>
      </Box>
    </Link>
  );
};

export default ProductCard;
